.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

h3 {
  color: #5a5c69;
}

/*.card {*/
/*  margin: 4px;*/
/*  padding: 10px;*/
/*}*/

.strike {
  text-decoration: line-through;
}

.no_padding {
  padding: 4px !important;
  vertical-align: middle !important;
}

.new .header {
  font-weight: bolder;
}

.opened {
  background-color: #f8f8f8;
}

.example-enter {
  max-height: 0%;
}

.example-enter.example-enter-active {
  max-height: 100%;
  transition: height 500ms ease-in;
}

.example-leave {
  opacity: 1;
}

.example-leave.example-leave-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}

.to_remove {
  padding: 5px;
  margin: 1px;
  border: 1px solid #6060b0;
  cursor: pointer;
  /*display: inline;*/
  background: #e0e0ff;
  border-radius: 4px;
}

.to_remove:hover {
  background: #ffc0c0;
  border: 1px solid #b06060;
}

.to_add {
  padding: 5px;
  margin: 1px;
  /*border: 1px solid #60b060;*/
  /*background: #e0ffe0;*/
  cursor: pointer;
  display: inline;
  text-decoration: gray dashed;
  color: #888;
  border-radius: 4px;
}

.to_add:hover {
  background: #eee;
}

.messages-list {
  background-color: #ddd;
  padding: 6px;
  overflow: hidden;
}

.message-block {
  overflow: hidden;
}

.my-message {
  float: right;
  clear:right;
  background: #435f7a;
  color: #f5f5f5;
  display: inline-block;
  padding: 10px 15px;
  border-radius: 20px;
  max-width: 450px;
  line-height: 130%;
  margin: 2px;
}

.they-message {
  background: #fff;
  color: #333;
  display: inline-block;
  padding: 10px 15px;
  border-radius: 20px;
  max-width: 450px;
  line-height: 130%;
  margin: 2px;
}

.sent_date {
  font-size: smaller;
  font-style: italic;
  filter: opacity(0.7);
}


.list-group-item {
  padding: 8px;
}

@media (max-width: 767px) {
  .list-group-item {
    padding: 0;
  }
}

.list-group-item a {
  padding: 12px;
}

.padding5 {
  padding: 5px;
}

.avatar_ico {
  max-width: 32px;
  max-height: 32px;
}

.href {
  cursor: pointer;
}

.navbar {
  padding-top: 4px;
  padding-bottom: 4px;
}

.center-box {
  margin: auto;
  padding-top: 30px;
  padding-bottom: 30px;
  filter: opacity(40%);
}

img.main_avatar {
  max-width: 128px;
}

img.avatar_small {
  max-width: 48px;
  padding: 0.05rem;
}

img.avatar_xsmall {
  max-width: 36px;
  padding: 0.05rem;
}

.container {
  padding-left: 10px;
  padding-right: 10px;
}

.row {
  margin: 0;
}

.top-logo {
  margin-bottom: 5px;
}

.display-linebreak {
  white-space: pre-line;
}

body {
  font-family: Roboto, HelveticaNeue, Arial, sans-serif;
  font-size: initial;
}


b {
  font: 700 16px/24px Roboto, HelveticaNeue, Arial, sans-serif;
  /*font: 500 16px/24px "Google Sans", Roboto, HelveticaNeue, Arial, sans-serif;*/
}

h1, h2, h3, h4, h5 {
  font-family: HKGroteskPro,serif;
}

.brand {
  font-family: HKGroteskPro,serif;
}

.pull-left {
  float: left;
  margin-right: 12px;
}

.pull-right {
  text-align: right;
}
.pull-center {
  text-align: center;
}

.clipped {
  max-height: 300px;
  overflow: hidden;
}

.clipped:before {
  /*content:'';*/
  /*width:100%;*/
  /*height:100%;*/
  /*position:absolute;*/
  /*left:0;*/
  /*top:0;*/
  /*background:linear-gradient(transparent 150px, white);*/
}
